<div class="footer">

    <div class="icon">
       
        <i class="fb fab fa-facebook"></i>
      
        <i class="tw fab fa-twitter"></i>
      
        <i class="wa fab fa-whatsapp"></i>
      
        <i class="in fab fa-instagram"></i>
    </div>
  
    <p class="copyrights">
      ©2021 KAJAH INDUSTRIAL. All Rights Reserved | Designed by <span><a href="https://www.zeroesandones.in/" target="blank">ZEROESANDONES</a></span> 
    </p>
  
  </div>