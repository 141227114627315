<div class="banner">
  <img
    src="../../../assets/images/slider6.jpg"
    width="100%"
    height="400px"
    alt=""
  />
</div>

<div class="container-top">
  <div class="navigation">
    <a (click)="goBack()">Go Back </a>
  </div>
</div>

<section class="section">
  <div class="section-container" data-aos="fade-up" data-aos-duration="1500">
    <div>
      <div>
        <h1>KAJAH INDUSTRIES</h1>
        <p>
          Welcome to Kajah Industries! Our company has recently extended its
          fruitful branches into the packaging sector. We manufacture a wide
          range of packaging products made out of craft papers. We procure
          excellent corrugated paper sheets from reliable sources and bring to
          you the best definition of packaging. The corrugated material is noted
          for its toughness, durability and high quality. It also enjoys the
          advantage of being eco-friendly over the other wrappers. The
          composition makes it both reusable and recyclable.
        </p>
      </div>
    </div>
  </div>

  <div class="section-container" data-aos="fade-up" data-aos-duration="1500">
    <h1>Our Products</h1>
    <div class="flex-container">
      <div>
        <h2>Corrugated Paper Box</h2>
        <p>
          The layered packaging product is stiff and durable. The manufacturing
          of these boxes is strictly standardized to undergo several tests to
          evaluate their bursting strength and compression strength. This makes
          the product bonafide. The dimensions of the boxes can be altered three
          ply, five ply, seven ply and nine ply to best suit your demands.
        </p>
      </div>
      <div>
        <img src="../../../assets/images/product1.jpg" alt="" />
      </div>
    </div>
  </div>

  <div class="section-container" data-aos="fade-up" data-aos-duration="1500">
    <div class="flex-container">
      <div>
        <h2>Corrugated Paper Roll</h2>
        <p>
          These corrugated rolls are made of craft papers of high calibre that
          are fine packing units to wrap brittle items like glassware,
          plasticware, ceramics, and steel utensils.
        </p>
      </div>
      <div>
        <img src="../../../assets/images/roll.jpg" alt="" />
      </div>
    </div>
  </div>

  <div class="section-container" data-aos="fade-up" data-aos-duration="1500">
    <div class="flex-container">
      <div>
        <h2>Printed Corrugated Box</h2>
        <p>
          We ensure the distinction of your brand by supplying printed and
          multi-printed corrugated boxes. The printing is perfected in our
          industry as we take great care in expressing ​high-quality images,
          colour consistency, and good colour saturation.
        </p>
      </div>
      <div>
        <img src="../../../assets/images/product3.jpg" alt="" />
      </div>
    </div>
  </div>

  <div class="section-container" data-aos="fade-up" data-aos-duration="1500">
    <div class="flex-container">
      <div>
        <h2>Heavy-Duty Corrugated Box</h2>
        <p>
          Heavy-duty corrugated boxes are specially designed keeping in mind the
          need for safety during the transportation of heavy loads. Our team
          manufactures the boxes by setting the seal on the qualities of
          rigidity and strength. These can be used to ship heavyweight objects
          like consumer products, home appliances, furniture etc... Our
          packaging guarantees the sound haulage of the consignment.
        </p>
      </div>
      <div>
        <img src="../../../assets/images/product4.jpg" alt="" />
      </div>
    </div>
  </div>

  <div class="section-container" data-aos="fade-up" data-aos-duration="1500">
    <div class="flex-container">
      <div>
        <h2>Laminated Corrugated Box</h2>
        <p>
          These boxes that are available in different thickness options are
          known for their colour variety and attractive nature. Laminated
          HDPE/cellophane boxes are moisture-proof and can be successfully
          employed to dispatch edibles while maintaining their hygiene. The
          intact packing retains the freshness of the content.
        </p>
      </div>
      <div>
        <img src="../../../assets/images/product5.jpg" alt="" />
      </div>
    </div>
  </div>

  <div class="section-container" data-aos="fade-up" data-aos-duration="1500">
    <div class="flex-container">
      <div>
        <h2>Die Cut Corrugated Box</h2>
        <p>
          These are customizable boxes that can fit any shape and size under
          your specifications. They have an ideal dimensional accuracy and are
          easy to carry. Particularly used to move crockery, electronics, and
          food, these boxes can be outlined to include unique graphics of
          product information, pictures, and the company’s logo.
        </p>
      </div>
      <div>
        <img src="../../../assets/images/product6.jpg" alt="" />
      </div>
    </div>
  </div>

  <div class="section-container" data-aos="fade-up" data-aos-duration="1500">
    <div class="flex-container">
      <div>
        <h2>White Duplex Corrugated Box</h2>
        <p>
          Used to pack certain goods like instant foods, beverages, toothpaste,
          books/magazines and video cassette boxes, these highly durable boxes
          are plain-patterned in white. They also come in convenient shapes and
          formats. The packing is applauded for its seamless quality and the
          ability to last long. The color of the boxes can be altered to best
          suit your demands.
        </p>
      </div>
      <div>
        <img src="../../../assets/images/product7.jpg" alt="" />
      </div>
    </div>
  </div>

  <div class="section-container" data-aos="fade-up" data-aos-duration="1500">
    <div class="flex-container">
      <div>
        <h2>Custom Box</h2>
        <p>
          This option enables our customers to make the best use of our services
          by availing of corrugated packaging products that are in accordance
          with their needs. This can ensure the secure packing of the product.
          You can also sketch and format the product to make it look more
          relatable to the goods it carries. This service is in the best
          interests of our clients.
        </p>
      </div>
      <div>
        <img src="../../../assets/images/product8.png" alt="" />
      </div>
    </div>
  </div>
</section>
