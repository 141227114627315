import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';

import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
// import { slideInAnimation } from './app-routing.animation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  // animations: [slideInAnimation]
})
export class AppComponent implements OnInit {
  title = 'kajah';

  constructor(private router:Router){ }

  ngOnInit() {
     // AOS Library
     AOS.init();
  }

  onDeactivate() {
    document.body.scrollTop = 0;
  }

  prepareRoute(outlet:RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
  
}
