<!-- class="sticky-top" -->

<div class="header sticky-top"> 
  
    <nav class="header-links" routerLinkActive="active" 
    [routerLinkActiveOptions]="{exact: true}">
      
      <img src="../assets/images/KAJAH LOGO.png" alt=""  class="logo">
      
      <ul #menu>
        <li><a (click)="onLink()" routerLink="/home" routerLinkActive="active">Home</a></li>
        <li><a (click)="onLink()" routerLink="/about" routerLinkActive="active">About </a></li>
        <li><a (click)="onLink()" routerLink="/product" routerLinkActive="active">Products</a></li>
        <li><a (click)="onLink()" routerLink="/contact" routerLinkActive="active" >Contact</a></li>
      </ul>

    </nav>


    <label id="menu" class="icon-header" (click)="onClickMenu()">
      <i class="fa fa-bars"></i>
    </label> 
    
</div>