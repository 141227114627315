import { Component, OnInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { NgBodyScrollLockService } from 'ng-body-scroll-lock';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  @ViewChild('menu',{static: true})  menu: ElementRef;
  public isVerticalMenuOpen: boolean = false;

  constructor(private renderer:Renderer2,private bodyScrollLock: NgBodyScrollLockService) { }

  ngOnInit(): void {
  }

  onClickMenu() {

      if(this.menu.nativeElement.style.display === "block") {
        this.renderer.setStyle(this.menu.nativeElement, 'display','none');
        this.closeVerticalMenu();
      } else {
        this.renderer.setStyle(this.menu.nativeElement, 'display','block');
        this.openVerticalMenu(); 
      }
     
    }

  onLink() {
    if(window.screen.width <= 480) {
      this.onClickMenu();
    }   
  }

  public openVerticalMenu() {
    this.isVerticalMenuOpen = true;
    this.bodyScrollLock.DisableBodyScroll(this.menu.nativeElement);
  }

  public closeVerticalMenu() {
    this.isVerticalMenuOpen = false;
    this.bodyScrollLock.EnableBodyScroll(this.menu.nativeElement);
  }


}
