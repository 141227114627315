
<app-header></app-header> 
<!-- [@routeAnimations]="prepareRoute(outlet)" -->
<div> 
    <router-outlet (deactivate)="onDeactivate()" #outlet="outlet"></router-outlet>
</div>
<app-footer></app-footer>

<!-- <router-outlet (deactivate)="onDeactivate()"></router-outlet> -->
<!-- <div 
    [@routeAnimation]="localOutlet && localOutlet.activatedRouteData && localOutlet.activatedRouteData['animation']" > 
    <router-outlet #localOutlet="outlet" (deactivate)="onDeactivate()"></router-outlet>
</div> -->