import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $:any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit() {
        $(document).ready(function() {
          $("#myCarousel").carousel();
        }) 
  }

  images = ['../../../assets/images/1.jpg',
            '../../../assets/images/2.jpg',
            '../../../assets/images/3.jpg',
            '../../../assets/images/4.jpg',
            '../../../assets/images/5.jpg'
          ];

  onClick() {
    this.router.navigate(['/about']);
  }

  btnService() {
    this.router.navigate(['/product'])
  }
}