<div class="wrapper">
  <div class="contact">
    <div class="contact-info">
      <h2>Contact Info</h2>
      <hr />

      <div class="info">
        <label>
          <i class="fa fa-map-marker" aria-hidden="true"></i>
        </label>

        <ul>
          <li>130/1A1B & 130/2,</li>
          <li>Ellainayakkanpatti P.O,</li>
          <li>Keela Vallanadu - 628 851,</li>
          <li>Tuticorin,Tamilnadu.</li>
        </ul>
      </div>
      <!--End of info div-->

      <div class="info">
        <label>
          <i class="fa fa-phone" aria-hidden="true"></i>
        </label>
        <span>
          <a href="#">97902 62622</a>
          <a href="#">, 04630-262622</a>
        </span>
      </div>
      <!--End of info div-->

      <div class="info">
        <label>
          <i class="fa fa-envelope-o" aria-hidden="true"></i>
        </label>
        <span><a href="#">admin@kajahecopack.com</a></span>
      </div>
      <!--End of info div-->
    </div>
    <!--End of contact-info div-->

    <div class="contact-form">
      <h2>Get In Touch</h2>
      <hr />

      <form action="">
        <!-- <input type="text" placeholder="Name" autocomplete="off">
                 <input type="text" placeholder="Email" autocomplete="off">
                 <input type="text" placeholder="Subject" autocomplete="off"> -->
        <div>
          <input type="text" class="inputText" required autocomplete="off" />
          <span class="floating-label">Name</span>
        </div>
        <div>
          <input type="text" class="inputText" required autocomplete="off" />
          <span class="floating-label">Email</span>
        </div>
        <div>
          <input type="text" class="inputText" required autocomplete="off" />
          <span class="floating-label">Subject</span>
        </div>
        <div>
          <textarea required class="inputText"></textarea>
          <span class="floating-label">Message</span>
        </div>

        <!-- <textarea name="" placeholder="Message"></textarea> -->
        <input type="submit" value="Send Message" />
      </form>
    </div>
    <!--End of contact-form div-->
  </div>
</div>
