import { AfterViewInit, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit, AfterViewInit {

  constructor(private location: Location) {
    
   }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  goBack(){    
    this.location.back();

  }
}
